.units-search {
    border: 1px solid #ececec;
    border-radius: 8px;
    margin: 8px 0 8px 0;
    padding: 10px;
}

.card {
    margin-top: 15px;
    margin-bottom: 15px;
}

.accordion {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-body > form > .row {
    margin-top: 10px;
}

.breadcrumb {
    margin-bottom: 0 !important;
}

.form-group.row {
  margin: 5px 0 0 5px;
}

h5.unit-page-heading {
  margin-top: 15px;
}

.editHistoryBlock {
  text-align: right;
  font-style: italic;
}

.megszuntBadge {
  margin-left: 5px;
}

.unitTaskRadioContainer {
  display: flex;
  justify-content: baseline;
  gap: 10px;
  align-items: center; 
}

textarea.task-comments {
  margin: 0px 0 10px 0;
}