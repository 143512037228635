.login-container {
    width: 40%;
    min-width: 375px;
    border: 2px solid rgb(134, 134, 134);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-container > h2 {
    margin-bottom: 15px;
}
  
.login-row {
    margin-bottom: 15px;
}
